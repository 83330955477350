import axios from './api';


const getNeedsTreeByParent = async (needParentId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need/tree/${needParentId}`);

    return response.data;
};

const getAllNeeds = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need`);

    return response.data;
};


const createNeed = async (name, description, parentId, color, needType, visibility, rootActivityId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need`, {
        name, description, parentId, color, needType, visibility, rootActivityId
    });
    return response.data;
};

const createConsumableNeed = async (name, description, parentId, color, needType, visibility, rootActivityId, quantityMode, timeUnitId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need`, {
        name, description, parentId, color, needType, visibility, rootActivityId, quantityMode, timeUnitId
    });
    return response.data;
};


const updateNeed = async (id, data) => {
  const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need/${id}`, {
    ...data
  });
  
  return response.data;
};

const createNeedLevel = async (name, description, parentId, visibility, rootActivityId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need/level`, {
        name, description, parentId, visibility, rootActivityId
    });
    return response.data;
};
const updateNeedLevel = async (id, data) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need/${id}`, {
        ...data
    });

    return response.data;
};

const deleteNeed = async (id) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/need/${id}`);
    return response.data;
};



// assignments
const createAllocatedNeed = async ( activityId, needId, data) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/${activityId}`, {
        needId, ...data
    });
    return response.data;
};

const createAllocatedNeedConsumable = async ( activityId, needId, factor) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/${activityId}`, {
        needId, factor
    });
    return response.data;
};

const getActivityAllocatedNeeds = async (activityId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/${activityId}`);

    return response.data;
};

const updateAllocatedNeed = async (allocatedNeedId, data) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/${allocatedNeedId}`, data);

    return response.data;
};

const deleteAllocatedNeed = async (id) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/${id}`);
    return response.data;
};

// slices

const generateNeedSlices = async () => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/allocated-need/slice/activity/752`
    );

    return response.data;
};
const generateFlattenActivitySkillResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/generate-flatten-slice-sorted-by-skill`
    );

    return response.data;
};
const generateActivitySkillResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/slice-sorted-by-skill`
    );

    return response.data;
};
const generateActivityResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/slice`
    );

    return response.data;
};


export default {
    getAllNeeds,
    getNeedsTreeByParent,
    createNeed,
    createConsumableNeed,
    createNeedLevel,
    updateNeed,
    updateNeedLevel,
    deleteNeed,
    createAllocatedNeed,
    createAllocatedNeedConsumable,
    updateAllocatedNeed,
    deleteAllocatedNeed,
    getActivityAllocatedNeeds,
    generateNeedSlices,
    generateFlattenActivitySkillResource,
    generateActivitySkillResource,
    generateActivityResource
};

import axios from './api';


const listTeams = async (ignoreSingle = true) => {
    const response = await axios.get(`${window._env_.REACT_APP_TEAMS_API}/teams/team`);

    if (ignoreSingle) {
        return response.data.filter((team) => !team.single);
    }

    return response.data;
};

const getUserTeams = async (userId, ignoreSingle = true) => {
    const response = await axios.get(`${window._env_.REACT_APP_TEAMS_API}/teams/team/user/${userId}`);

    if (ignoreSingle) {
        return response.data.filter((team) => !team.single);
    }

    return response.data;
};

const createTeam = async (teamData) => {
    const response = await axios.post(`${window._env_.REACT_APP_TEAMS_API}/teams/team`, {
        name: teamData.name,
    });

    return response.data;
};

const updateTeam = async (teamId, teamData) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEAMS_API}/teams/team/${teamId}`, {
        name: teamData.name,
    });

    return response.data;
};

const deleteTeam = async (teamId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_TEAMS_API}/teams/team/${teamId}`);

    return response.data;
};

const addUserToTeam = async (userId, teamId) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEAMS_API}/teams/team/${teamId}/subscribe`, { userId });

    return response.data;
};

const removeUserFromTeam = async (userId, teamId) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEAMS_API}/teams/team/${teamId}/unsubscribe`, { userId });

    return response.data;
};

export default {
    listTeams,
    createTeam,
    updateTeam,
    deleteTeam,
    addUserToTeam,
    removeUserFromTeam,
    getUserTeams
};

import axios from './api';


const listResources = async (supplyParentId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/${supplyParentId}`);

    return response.data;
};


const createResource = async (label, description, parentId, color, quantity, calendarId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/resource`, {
        label, description, parentId, color, quantity, calendarId
    });
    return response.data;
};

const createLevel = async (label, description, parentId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/level`, {
        label, description, parentId
    });
    return response.data;
};

const updateResource = async (id, data) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/resource/${id}`, {
        ...data
    });

    return response.data;
};

const updateLevel = async (id, data) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/level/${id}`, {
        ...data
    });

    return response.data;
};

const deleteResource = async (id) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/${id}`);
    return response.data;
};

// skills

const listSkills = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/resources/skill`);

    return response.data;
};

const createSkill = async (name, description = '') => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/resources/skill`, {
        name, description
    });
    return response.data;
};

const updateSkill = async (id, name, description) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/resources/skill/${id}`, {
        name, description
    });

    return response.data;
};

const deleteSkill = async (id) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/resources/skill/${id}`);
    return response.data;
};

const addSkillsToResource = async (resourceId, skillIds) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/resource/${resourceId}/add-skills`, {ids: skillIds});

    return response.data;
};
const removeSkillsFromResource = async (resourceId, skillIds) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/supplies/supply/resource/${resourceId}/remove-skills`, {ids: skillIds});

    return response.data;
};

// assignments
const createAssignment = async (supplyId, activityId, skillId, quantity, startDateTime, endDateTime) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment`, {
        supplyId, activityId, skillId, quantity, startDateTime, endDateTime
    });
    return response.data;
};

const getActivityAssignment = async (activityId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/activity/${activityId}`);

    return response.data;
};

const updateAssignment = async (assignmentId, supplyId, activityId, skillId, quantity, startDateTime, endDateTime) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${assignmentId}`, {
        supplyId, activityId, skillId, quantity, startDateTime, endDateTime
    });

    return response.data;
};

const deleteAssignment = async (id) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${id}`);
    return response.data;
};

// slices

const generateFlattenActivityResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/generate-flatten-slice`
    );

    return response.data;
};
const generateFlattenActivitySkillResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/generate-flatten-slice-sorted-by-skill`
    );

    return response.data;
};
const generateActivitySkillResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/slice-sorted-by-skill`
    );

    return response.data;
};
const generateActivityResource = async (rootActivityId) => {
    const response = await axios.get(
        `${window._env_.REACT_APP_ACTIVITY_API}/resources/assignment/${rootActivityId}/resource/slice`
    );

    return response.data;
};


export default {
    listResources,
    createResource,
    createLevel,
    updateResource,
    updateLevel,
    deleteResource,
    listSkills,
    createSkill,
    updateSkill,
    deleteSkill,
    addSkillsToResource,
    removeSkillsFromResource,
    createAssignment,
    updateAssignment,
    deleteAssignment,
    getActivityAssignment,
    generateFlattenActivityResource,
    generateFlattenActivitySkillResource,
    generateActivitySkillResource,
    generateActivityResource
};

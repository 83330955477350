import axios from './api';


const allCustomFields = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/`);

    return response.data;
};

const defaultsCustomFields = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/defaults`);

    return response.data;
};

const planningCustomFields = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/planning/${planningId}`);

    return response.data;
};

const getCustomFieldById = async (customFieldId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}`);

    return response.data;
};

const createCustomField = async (name, type, planningId) => {
    const response = await axios.post(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field`, {
        name,
        numeric: type === 'numeric',
        text: type === 'text',
        choices: type === 'choice',
        planningId,
    });

    return response.data;
};

const updateCustomFieldName = async (planningId, customFieldId, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}/${planningId}/name`, { name });

    return response.data;
};

const deleteCustomField = async (planningId, customFieldId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${planningId}/${customFieldId}`);

    return response.data;
};

const addChoice = async (customFieldId, name, hachure, color, trame, rootActivityId) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${rootActivityId}/${customFieldId}/add-choice`, {
        name,
        color,
        hachure,
        trame,
    });

    return response.data;
};

const updateChoice = async (customFieldId, choice, name, hachure, color, trame, rootActivityId) => {
    const response = await axios.put(
        `${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${rootActivityId}/${customFieldId}/${encodeURIComponent( choice.name)}/update-choice`,
        {
            choicetypeId: choice.id,
            name,
            hachure,
            color,
            trame,
        }
    );

    return response.data;
};

const removeChoice = async (customFieldId, name, rootActivityId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${rootActivityId}/${customFieldId}/${name}/delete-choice`);

    return response.data;
};

const getCustomFieldActivityValue = async (customFieldId, activityId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}/${activityId}/value`);

    return response.data;
};

const updateCustomFieldActivityValue = async (customFieldId, activityId, value) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}/${activityId}/value`, {
        value,
    });

    return response.data;
};

const updateCustomFieldMultipleActivityValue = async (customFieldId, activityIdList, value) => {
    const response = await axios.put(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}/value`, {
        value,
        activityIdList
    });

    return response.data;
};

const getActivityCustomFieldName = async (customFieldId) => {
    const response = await axios.get(`${window._env_.REACT_APP_ACTIVITY_API}/activities/unified-field/${customFieldId}`,
    );

    return response.data;
};


export default {
    allCustomFields,
    defaultsCustomFields,
    planningCustomFields,
    createCustomField,
    updateCustomFieldName,
    deleteCustomField,
    addChoice,
    updateChoice,
    removeChoice,
    getCustomFieldActivityValue,
    updateCustomFieldActivityValue,
    getActivityCustomFieldName,
    updateCustomFieldMultipleActivityValue,
    getCustomFieldById
};

/* eslint no-underscore-dangle: "off", consistent-return: "off" */
import axios from 'axios';
import { createBrowserHistory } from 'history';
import { deleteTokenInfo, setTokenInfo } from '../helpers/localStorage.-helper';

// Request interceptors to add add token header to requests
axios.interceptors.request.use(
    (config) => {
        const modifiedConfig = config;
        if (!config.url.includes('refresh')) {
            const accessToken = localStorage.getItem('accessToken');
            if (accessToken) {
                modifiedConfig.headers.Authorization = `Bearer ${accessToken}`;
            }
            modifiedConfig.headers.Accept = 'application/json';
        }

        return modifiedConfig;
    },
    (error) => {
        Promise.reject(error);
    }
);

// Response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const originalRequest = error.config;
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken && error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            // const cleanAxiosInstance = axios.create();
            return axios.post(`${window._env_.REACT_APP_WALLET_API}/sessions/refresh`, { refreshToken }).then(
                (response) => {
                    if (response.status === 200) {
                        setTokenInfo(response.data);
                        return axios(originalRequest);
                    }
                    if (response.status === 401) {
                        deleteTokenInfo();
                        createBrowserHistory().push('/');
                        window.location.reload();
                    }
                },
                (refreshError) => {
                    if (refreshError.response.status === 422 || refreshError.response.status === 401) {
                        deleteTokenInfo();
                        createBrowserHistory().push('/');
                        window.location.reload();
                    }
                }
            );
        }

        return Promise.reject(error);
    }
);

export default axios;

import axios from './api';


/*
 DAYS TAB
 */

// Day definitions
const listDayDefinition = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions`);

    return response.data;
};

const showDayDefinition = async (id) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${id}`);

    return response.data;
};

const createDayDefinition = async (name, color) => {
    const response = await axios.post(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions`, { name, color });

    return response.data;
};

const updateDayDefinitionName = async (dayDefinition, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}`, { name });

    return response.data;
};

const updateDefaultDayDefinition = async (dayDefinition) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}/as-default`);

    return response.data;
};


const updateDayDefinitionColor = async (dayDefinition, color) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}/modify-color`, {
        color,
    });

    return response.data;
};

const deleteDayDefinition = async (dayDefinition) => {
    const response = await axios.delete(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}`);

    return response.data;
};

const addTimeSlots = async (dayDefinition, timeSlotIds) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}/add-timeslot`, {
        timeslotIds: timeSlotIds,
    });

    return response.data;
};

const removeTimeSlot = async (dayDefinition, timeSlotsIds) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/${dayDefinition.id}/remove-timeslot`, {
        timeslotIds: timeSlotsIds,
    });

    return response.data;
};

const listTimeUnits = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/day-definitions/time-units`);

    return response.data;
};

// Time Slots
const listTimeSlots = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/time-slots`);

    return response.data;
};

const createTimeSlot = async (values) => {
    const response = await axios.post(`${window._env_.REACT_APP_CALENDAR_API}/calendars/time-slots`, values);

    return response.data;
};

const updateTimeSlotName = async (timeSlot, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/time-slots/${timeSlot.id}`, { name });

    return response.data;
};

const deleteTimeSlot = async (timeSlot) => {
    const response = await axios.delete(`${window._env_.REACT_APP_CALENDAR_API}/calendars/time-slots/${timeSlot.id}`);

    return response.data;
};

/*
 PERIODS TAB
 */

const listPeriods = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods`);

    return response.data;
};

const createPeriod = async (values) => {
    const response = await axios.post(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods`, values);

    return response.data;
};

const updatePeriodName = async (period, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods/${period.id}`, {
        name,
    });

    return response.data;
};

const updatePeriodNbDay = async (period, nbDay) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods/${period.id}/update-days-number`, {
        periodDaysNumber: nbDay,
    });

    return response.data;
};

const updatePeriodDayDefinition = async (period, index, dayDefinitionId) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods/${period.id}/update-day-definition`, {
        daysIndex: index,
        dayDefinitionId,
    });

    return response.data;
};

const removeDayDefinition = async (period, index) => {
    const response = await axios.delete(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods/${period.id}/remove-day-definition`, {
        data: {
            daysIndex: index,
        },
    });

    return response.data;
};

const deletePeriod = async (period) => {
    const response = await axios.delete(`${window._env_.REACT_APP_CALENDAR_API}/calendars/periods/${period.id}`);

    return response.data;
};

/*
 CALENDARS TAB
 */

const listCalendars = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar`);

    return response.data;
};

const createCalendar = async (values) => {
    const response = await axios.post(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar`, values);

    return response.data;
};

const updateCalendarName = async (calendar, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}`, {
        name,
    });

    return response.data;
};

const updateDefaultCalendar = async (calendar) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/as-default`);

    return response.data;
};

const deleteCalendar = async (calendar) => {
    const response = await axios.delete(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}`);

    return response.data;
};

// Periods
const addPeriod = async (calendar, period, startAt) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/add-period`, {
        periodId: period.id,
        start: startAt,
    });

    return response.data;
};

const removePeriod = async (calendar, startAt) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/remove-period`, {
        start: startAt,
    });

    return response.data;
};

// Exclusions
const importExclusions = async (calendar, dayDefinition, file) => {
    const response = await axios.post(
        `${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/icalendar/${dayDefinition.id}`,
        file,
        {
            headers: {
                'Content-Type': 'application/octet-stream',
            },
        }
    );

    return response.data;
};
const listExclusions = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/specific-period`);

    return response.data;
};

const addExclusion = async (calendar, name, startAt, endAt, dayDefinition) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/add-specific-period`, {
        name,
        startDate: startAt,
        endDate: endAt,
        dayDefinitionId: dayDefinition.id,
    });

    return response.data;
};

const removeExclusion = async (calendar, exclusion) => {
    const response = await axios.put(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendar.id}/remove-specific-period`, {
        specificPeriodId: exclusion.id,
    });

    return response.data;
};

const listNoWorkingHour = async (calendarId, start, end) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}/no-working-hour`, {
        params: { start, end },
    });
    return response.data;
};

const listNoWorkingReal = async (calendarId, start, end) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}/no-working-real`, {
        params: { start, end },
    });
    return response.data;
};

const listNoWorkingDay = async (calendarId, start, end) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}/no-working-day`, {
        params: { start, end },
    });
    return response.data;
};

// No working week, month
const listNoWorkingWeek = async (calendarId, start, end) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}/no-working-week`, {
        params: { start, end },
    });
    return response.data;
};

const listNoWorkingMonth = async (calendarId, start, end) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}/no-working-month`, {
        params: { start, end },
    });
    return response.data;
};

const getCalendarById = async (calendarId) => {
    const response = await axios.get(`${window._env_.REACT_APP_CALENDAR_API}/calendars/calendar/${calendarId}`);

    return response.data;
}

export default {
    // Day definition
    listDayDefinitions: listDayDefinition,
    // DayDefinition
    createDayDefinition,
    updateDayDefinitionName,
    updateDefaultDayDefinition,
    updateDayDefinitionColor,
    deleteDayDefinition,
    addTimeSlots,
    removeTimeSlot,
    showDayDefinition,
    listTimeUnits,
    // Time Slots
    listTimeSlots,
    createTimeSlot,
    updateTimeSlotName,
    deleteTimeSlot,
    /*  PERIODS TAB   */
    listPeriods,
    createPeriod,
    updatePeriodName,
    updatePeriodNbDay,
    updatePeriodDayDefinition,
    removeDayDefinition,
    deletePeriod,
    /*  CALENDARS TAB   */
    listCalendars,
    createCalendar,
    updateCalendarName,
    updateDefaultCalendar,
    deleteCalendar,
    listNoWorkingDay,
    // Periods
    addPeriod,
    removePeriod,
    // Exclusions
    importExclusions,
    listExclusions,
    addExclusion,
    removeExclusion,
    listNoWorkingHour,
    listNoWorkingReal,
    listNoWorkingMonth,
    listNoWorkingWeek,
    getCalendarById
};

import axios from './api';


const listAllTemplate = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_TEMPLATE_API}/print/template`);
    // @TODO Gros probleme de performance interdire ou vider le retour.
    return response.data;
};

const listAllPersonalTemplate = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/personal`);
    // @TODO Gros probleme de performance interdire ou vider le retour.
    return response.data;
};

const listAllPublicTemplate = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/public`);
    // @TODO Gros probleme de performance interdire ou vider le retour.
    return response.data;
};

const createTemplate = async (name, personal) => {
    const response = await axios.post(`${window._env_.REACT_APP_TEMPLATE_API}/print/template`, { name, personal });

    return response.data;
};

const showTemplate = async (templateId) => {
    const response = await axios.get(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}`);

    return response.data;
};

const deleteTemplate = async (templateId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}`);

    return response.data;
};

const updateTemplateName = async (templateId, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}/name`, { name });

    return response.data;
};
const updateTemplate = async (templateId, name, personal) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}`, { name, personal });

    return response.data;
};

const updateTemplateElements = async (templateId, elements) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}/elements`, { elements });

    return response.data;
};

const removeTemplateElement = async (templateId, container) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}/remove-element`, { container });

    return response.data;
};

const updateTemplateColors = async (templateId, colors) => {
    const response = await axios.put(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/${templateId}/color`, {
        headerColor: colors.headerColor,
        footerColor: colors.footerColor,
    });

    return response.data;
};

const privatizeTemplate = async (templateId, name) => {
    const response = await axios.post(`${window._env_.REACT_APP_TEMPLATE_API}/print/template/privatize/${templateId}`, { name });

    return response.data;
};

export default {
    listAllTemplate,
    listAllPersonalTemplate,
    listAllPublicTemplate,
    createTemplate,
    updateTemplate,
    showTemplate,
    deleteTemplate,
    updateTemplateName,
    privatizeTemplate,
    updateTemplateElements,
    updateTemplateColors,
    removeTemplateElement
};

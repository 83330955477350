import axios from './api';


/*
 FOLDERS
 */

const listFolders = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder`);

    return response.data;
};

const showFolder = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}`);

    return response.data;
};
const showFolderByUrl = async (url, folderId) => {
    const response = await axios.get(`https://folder.${url}/folders/folder/${folderId}`);

    return response.data;
};

const listSubFolders = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/subfolder`);

    return response.data;
};

const listArchives = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/archive`);

    return response.data;
};

const listPlannings = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/planning`);

    return response.data;
};

const createFolder = async (name, parentId = 0, inheritParentPermission=false) => {
    const response = await axios.post(`${window._env_.REACT_APP_FOLDER_API}/folders/folder`, { name, parentId, inheritParentPermission });

    return response.data;
};

const deleteFolder = async (folderId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}`);

    return response.data;
};

const renameFolder = async (folderId, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/name`, {
        name,
    });

    return response.data;
};

/*
 FOLDERS PERMISSIONS
 */

const listFolderPermissions = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/permissions`);

    return response.data;
};

const getUserPermissionOnFolder = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/secure/folder-access/${folderId}`);

    return response.data;
};

const createFolderPermission = async (folderId, teamId, access, isPropagate) => {
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/add-permission`, {
        access,
        teamId,
        isPropagate
    });

    return response.data;
};

const deleteFolderPermission = async (folderId, permissionId) => {
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/folder/${folderId}/remove-permission/${permissionId}`);

    return response.data;
};

/*
 PLANNINGS PERMISSIONS
 */

const listPlanningPermissions = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}/permissions`);

    return response.data;
};

/*eslint-disable */
const createPlanningPermission = async (planningId, teamId, access, propagate) => {
    const isPropagate = propagate ?? false;
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}/add-permission`, {
        access,
        teamId,
        isPropagate
    });
    return response.data;
};

const deletePlanningPermission = async (planningId, permissionId) => {
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}/remove-permission/${permissionId}`);

    return response.data;
};

/*
 PLANNINGS
 */

const createPlanning = async (name, folderId) => {
    const response = await axios.post(`${window._env_.REACT_APP_FOLDER_API}/folders/planning`, {
        name,
        parentId: folderId,
    });

    return response.data;
};

const showPlanning = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}`);

    return response.data;
};

const showPlanningByUrl = async (url, planningId) => {
    const response = await axios.get(`https://folder.${url}/folders/planning/${planningId}`);

    return response.data;
};

const archivePlanning = async (planningId, name) => {
    const response = await axios.post(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}`, {
        name,
    });

    return response.data;
};

const deletePlanning = async (planningId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}`);

    return response.data;
};

const renamePlanning = async (planningId, name) => {
    const response = await axios.put(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/${planningId}/name`, {
        name,
    });

    return response.data;
};

/*
 SANDBOX
 */

const listSandboxPlannings = async () => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/sandbox/planning`);

    return response.data;
};

const showSandboxPlanning = async (folderId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/sandbox/planning/${folderId}`);

    return response.data;
};

const createSandboxPlanning = async (name) => {
    const response = await axios.post(`${window._env_.REACT_APP_FOLDER_API}/folders/sandbox/planning`, { name });

    return response.data;
};

const deleteSandboxPlanning = async (planningId) => {
    const response = await axios.delete(`${window._env_.REACT_APP_FOLDER_API}/folders/sandbox/planning/${planningId}`);

    return response.data;
};

// notification

const notifyUsers = async (userIds, teamIds, planningName, userName, archiveUrl) => {
    const response = await axios.post(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/notify-users`, { userIds, teamIds, planningName, userName, archiveUrl});

    return response.data;
};

const getPlanningByRootActivityId = async (rootActivityId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/root/${rootActivityId}/planningId`);

    return response.data;
};

const getRelatedPlanning = async (planningId) => {
    const response = await axios.get(`${window._env_.REACT_APP_FOLDER_API}/folders/planning/all-related-planning/${planningId}`);

    return response.data;
};

export default {
    // Folders
    listFolders,
    showFolder,
    showFolderByUrl,
    listSubFolders,
    listArchives,
    listPlannings,
    createFolder,
    deleteFolder,
    renameFolder,
    // Folders Permissions
    listFolderPermissions,
    createFolderPermission,
    deleteFolderPermission,
    getUserPermissionOnFolder,
    // Plannings Permissions
    listPlanningPermissions,
    createPlanningPermission,
    deletePlanningPermission,
    // Plannings
    createPlanning,
    showPlanning,
    archivePlanning,
    deletePlanning,
    renamePlanning,
    // Sandbox
    listSandboxPlannings,
    showSandboxPlanning,
    createSandboxPlanning,
    deleteSandboxPlanning,
    showPlanningByUrl,
    notifyUsers,
    getPlanningByRootActivityId,
    getRelatedPlanning
};
